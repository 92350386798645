import {
  ArrayUtils,
  DaafMessage,
  DaafModal,
  DaafModalTemplate,
  DaafPopup,
  ObjectUtils,
  SessionUtils,
  StringUtils,
  UserUtils,
} from "@alpha/com.bizentro.daaf.front.framework";
import Grid from "components/grid/Grid";
import produce from "immer";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import TrdService from "services/TrdService";
import useAppEnv from "utils/hook/useAppEnv";
import TrdUtil from "utils/TrdUtils";
import TrdQueryViewerPopup from "./TrdQueryViewerPopup";
import { confirmMessage } from "utils/CommonUtils";
import { useRef } from "react";
import Form from "components/common/form/Form";

function TrdTableInitCreatePopup({ callback, ...props }) {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTable, setSelectedTable] = useState([]);
  const [tableList, setTableList] = useState([]);

  const { app, env } = useAppEnv();

  const [dbType, setDbType] = useState(env.dbType);

  const columns = [
    {
      field: "trdNm",
      headerName: "Trd 명",
      renderCell: (data) => {
        return data.trdMst.trdNm;
      },
    },
    {
      field: "tablePhysicalNm",
      headerName: "Table Physical Name",
    },
    {
      field: "tableLogicalNm",
      headerName: "Table Logical Name",
    },
    {
      field: "quertView",
      headerName: "Query",
      renderCell: (data) => {
        return (
          <Button
            variant="outline-success"
            onClick={(e) => {
              e.stopPropagation();
              onOpenQueryPeview(data.queryInfo);
            }}
          >
            Preview
          </Button>
        );
      },
    },
    {
      field: "isError",
      headerName: "Query Error",
      center: true,
      renderCell: (data) => {
        let text = "";
        let variant = "";
        if (data.queryInfo.isError) {
          text = "Error";
          variant = "danger";
        } else {
          text = "Success";
          variant = "outline-primary";
        }

        return (
          <Button
            variant={variant}
            onClick={(e) => {
              e.stopPropagation();
              onOpenQueryErrorPopup(data.queryInfo);
            }}
          >
            {text}
          </Button>
        );
      },
    },
    {
      field: "querySuccessYn",
      headerName: "Excute Success Yn",
      renderCell: (data) => {
        let text = "";
        let variant = "";
        let onClick = (e) => {
          e.stopPropagation();
        };
        if (StringUtils.isEmpty(data.queryInfo.querySuccessYn)) {
          text = "Not Executed";
          variant = "outline-secondary";
        } else if (data.queryInfo.querySuccessYn === "Y") {
          text = "Success";
          variant = "outline-primary";
        } else {
          text = "Failure";
          variant = "outline-danger";
          onClick = (e) => {
            e.stopPropagation();
            onOpenQueryErrorPopup({ errorMessage: data.queryErrorMessage });
          };
        }

        return (
          <Button variant={variant} onClick={onClick}>
            {text}
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    getTableList();
  }, []);

  /**
   * TRD에 등록된 모든 테이블을 호출함
   */
  const getTableList = async () => {
    const result = await TrdService.getTableListWithoutTrd({
      appEnvId: env.appEnvId,
    });
    if (result.isError) {
      return DaafMessage.alert(result.message, "error");
    }

    let _dbType = env.dbType;
    const connection = SessionUtils.get("connection");
    if (ObjectUtils.isEmpty(connection)) {
      DaafMessage.alert("서버 연결이 필요합니다.", "warning");
    } else {
      _dbType = connection.databaseType;
    }

    const tableListWithCreateQuery = result.data.map((tableData) => {
      const obj = { ...tableData };
      obj.queryInfo = TrdUtil.generateTableCreateQuery(tableData, _dbType);
      obj.queryInfo.queryCompId = StringUtils.getUuid();
      //drop 문구 추가
      obj.queryInfo.queryContents = `${
        TrdUtil.generateTableDropQuery(tableData, null, dbType.current)
          .queryContents
      } ${obj.queryInfo.queryContents}`;

      return obj;
    });
    setDbType(_dbType);
    setTableList(tableListWithCreateQuery);
  };

  const genQuery = () => {
    const tableListWithCreateQuery = tableList.map((tableData) => {
      const obj = { ...tableData };
      obj.queryInfo = TrdUtil.generateTableCreateQuery(tableData, dbType);
      obj.queryInfo.queryCompId = StringUtils.getUuid();
      //drop 문구 추가
      obj.queryInfo.queryContents = `${
        TrdUtil.generateTableDropQuery(tableData, null, dbType.current)
          .queryContents
      } ${obj.queryInfo.queryContents}`;

      return obj;
    });
    setTableList(tableListWithCreateQuery);
    DaafMessage.alert("Query Generated", "success");
  };

  /**
   * 쿼리 프리뷰 팝업을 오픈
   * @param {*} query
   */
  const onOpenQueryPeview = (query) => {
    DaafPopup.open(
      <TrdQueryViewerPopup
        query={query.queryContents}
        editable={false}
        callback={onClickConfirm}
      />,
      {
        style: { content: { width: "900px" } },
      }
    );
  };

  const onOpenSelectedQueryPopup = async () => {
    if (ArrayUtils.isEmpty(selectedTable)) {
      return DaafMessage.alert("No table has been selected.", "warning");
    }
    const query = selectedTable.map((table) => {
      return table.queryInfo.queryContents;
    });

    const joinQuery = query.join("");
    const relationQueryList = await getForeignQuery();
    let relQuery = "";
    relQuery = relationQueryList.map((rq) => rq.queryContents);
    onOpenQueryPeview({ queryContents: joinQuery + relQuery.join("") });
  };

  const getForeignQuery = async () => {
    const relationList = [];
    for (const table of selectedTable) {
      if (!ArrayUtils.isEmpty(table.relation)) {
        const trd = await TrdService.getTrd(table.trdMst);
        for (const rel of table.relation) {
          const querySet = TrdUtil.generateForeignKeyQuery(
            rel,
            trd.data,
            dbType.current
          );
          querySet.queryExecuteYn = "Y";
          relationList.push(querySet);
        }
      }
    }
    return relationList;
  };

  const onClickConfirm = async (e) => {
    const result = await confirmMessage("Do you want to execute the query?");
    if (!result) return false;

    const cQueryList = selectedTable.map((table) => {
      table.queryInfo.queryExecuteYn = "Y";
      return table.queryInfo;
    });
    const rQueryList = await getForeignQuery();
    // const queryList = cQueryList;
    const queryList = cQueryList.concat(rQueryList);

    if (queryList.some((query) => query.isError)) {
      return DaafMessage.alert(
        "There is an error in the generated query. Please check the list.",
        "warning"
      );
    }

    const connection = SessionUtils.get("connection");
    if (!connection) return DaafMessage.alert("연결 정보가 없습니다.", "error");

    const queryResult = await TrdService.excuteQuery({
      ...connection,
      queryList: queryList,
    });
    console.log(queryResult);
    if (!queryResult.isError) {
      const queryResultData = queryResult.data.data;
      const excutedQueryList = queryResultData.map((r) => {
        const obj = { ...r };
        obj.errorCheckedYn = "N";
        obj.appEnvId = env.appEnvId;
        obj.userId = UserUtils.getId();
        return obj;
      });
      setTableList(
        tableList.map((table) => {
          const obj = { ...table };
          const result = excutedQueryList.find(
            (eq) => eq.queryCompId === obj.queryInfo.queryCompId
          );
          if (result) {
            obj.queryInfo = { ...result };
          }
          return obj;
        })
      );
      return excutedQueryList;
    } else {
      return false;
    }
  };

  /**
   * 쿼리 생성 오류 팝업 호출
   * @param {*} e
   */
  const onOpenQueryErrorPopup = (data) => {
    if (data.isError) {
      DaafPopup.open(
        <TrdQueryViewerPopup query={data.errorMessage} editable={false} />,
        { style: { content: { width: "900px" } } }
      );
    }
  };

  return (
    <DaafModal>
      <DaafModal.Header title={"Database Table Create Process"} />
      <DaafModal.Body>
        <DaafModalTemplate>
          <Row>
            <Col xs={7} />
            <Col xs={3}>
              <Form.Select
                value={dbType}
                onChange={(e) => setDbType(e.target.value)}
                size={"sm"}
                className={"mb-3"}
              >
                <option value={"MSSQL"}>MSSQL</option>
                <option value={"MYSQL"}>MYSQL</option>
                <option value={"ORACLE"}>ORACLE</option>
              </Form.Select>
            </Col>
            <Col xs={2}>
              <Button size={"sm"} onClick={genQuery}>
                Generate Query
              </Button>
            </Col>
          </Row>
          <Grid
            numbering
            columns={columns}
            rows={tableList}
            stickyHeader={true}
            gridBoxStyle={{ maxHeight: "650px" }}
            useCheckBox={true}
            getId={(data) => data.tableMstId}
            onChangeCheckbox={(selectedTable) => {
              setSelectedTable(selectedTable);
            }}
          />
        </DaafModalTemplate>
      </DaafModal.Body>
      <DaafModal.Footer>
        <DaafModal.Footer.ProgressButton
          side="right"
          variant="primary"
          onClick={onClickConfirm}
        >
          Query execute
        </DaafModal.Footer.ProgressButton>
        <DaafModal.Footer.ProgressButton
          side="right"
          variant="success"
          onClick={onOpenSelectedQueryPopup}
        >
          Query Preview
        </DaafModal.Footer.ProgressButton>
      </DaafModal.Footer>
    </DaafModal>
  );
}

export default TrdTableInitCreatePopup;
