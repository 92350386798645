import {
  ArrayUtils,
  DaafMessage,
  DaafModal,
  DaafPopup,
  ObjectUtils,
  SessionUtils,
  StringUtils,
  UserUtils,
} from "@alpha/com.bizentro.daaf.front.framework";
import UModalTemplate from "@alpha/com.bizentro.daaf.front.framework/dist/component/modal/UModalTemplate";
import { Enums } from "app/Enums";
import Form from "components/common/form/Form";
import Grid from "components/grid/Grid";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { MdDelete } from "react-icons/md";
import TrdService from "services/TrdService";
import { confirmMessage } from "utils/CommonUtils";
import useAppEnv from "utils/hook/useAppEnv";
import TrdUtil from "utils/TrdUtils";
import TrdQueryFailInfoPopup from "./TrdQueryFailInfoPopup";
import TrdQueryStateGrid from "./TrdQueryStateGrid";
import TrdQueryViewerPopup from "./TrdQueryViewerPopup";

/**
 * 쿼리 생성하는 팝업
 * @returns
 */
const TrdQueryPopup = ({ trd, ...props }) => {
  const [queryGridData, setQueryGridData] = useState([]);
  const [errorQueryGridData, setErrorQueryGridData] = useState([]);
  const [queryExecuted, setQueryExecuted] = useState(false);
  const { env, app } = useAppEnv();
  useEffect(() => {
    getQuery();
  }, []);

  const getQuery = async () => {
    let dbType = env.dbType;
    const connection = SessionUtils.get("connection");
    if (connection) {
      dbType = connection.databaseType;
    }
    //쿼리 실행여부 keyValue 추가
    const generateQueryResult = await TrdUtil.generateQuery(
      {
        ...trd.info,
        tableList: trd.tableList,
        areaList: trd.areaList,
      },
      dbType
    );

    //이전에 실패했던 쿼리도 다시 불러옴
    const queryHistory = await TrdService.getFailedQueryHistory(trd.info);
    setErrorQueryGridData(queryHistory.data || []);
    if (generateQueryResult)
      setQueryGridData(
        generateQueryResult.map((qs) => {
          const querySet = { ...qs };
          querySet.queryExecuteYn = "Y";
          querySet.queryCompId = StringUtils.getUuid();
          return querySet;
        })
      );
  };

  const onClickConfirm = async () => {
    if (props.callback) {
      const exeQuery = queryGridData.filter((q) => q.queryExecuteYn === "Y");
      await excuteQuery(exeQuery)
        .then((qData) => {
          if (qData) {
            setQueryExecuted(true);
            props.callback(qData);
          }
        })
        .catch((err) => {
          DaafMessage.alert(err.message);
        });
    }
  };

  /**
   * 쿼리 실행
   * @returns
   */
  const excuteQuery = async (execQueryList) => {
    const connection = SessionUtils.get("connection");
    if (!connection) return DaafMessage.alert("연결 정보가 없습니다.", "error");

    const queryResult = await TrdService.excuteQuery({
      ...connection,
      queryList: execQueryList,
    });
    if (!queryResult.isError) {
      const queryResultData = queryResult.data.data;

      const excutedQueryList = queryResultData.map((r) => {
        const obj = { ...r };
        obj.errorCheckedYn = "N";
        obj.trdId = trd.info.trdId;
        obj.appEnvId = env.appEnvId;
        obj.userId = UserUtils.getId();
        return obj;
      });
      setQueryGridData(
        queryGridData.map((qg) => {
          const result = excutedQueryList.find(
            (eq) => eq.queryCompId === qg.queryCompId
          );
          if (result) {
            qg = { ...result };
          }
          return qg;
        })
      );
      return excutedQueryList;
    } else {
      return false;
    }
  };

  const onViewQuery = (query, index) => {
    const callback = (confirmedQuery) => {
      DaafPopup.close();
    };
    DaafPopup.open(
      <TrdQueryViewerPopup
        query={query}
        callback={callback}
        editable={false}
      />,
      {
        style: { content: { width: "650px" } },
      }
    );
  };

  /**
   * 오류 쿼리 확인 로직
   * @param {*} data
   */
  const checkErrorQuery = async (data, index, yn) => {
    const body = {
      ...data,
      errorCheckedYn: yn,
      errorCheckedUserId: UserUtils.getId(),
    };
    const result = await TrdService.updateTrdQuery(body);
    if (!result.isError) {
      const newErrorQueryGridData = [...errorQueryGridData];
      newErrorQueryGridData[index].errorCheckedYn = "Y";
      setErrorQueryGridData(newErrorQueryGridData);
    } else {
      DaafMessage.alert(result.message, "error");
    }
  };

  const showErrorLogPopup = (errorMessage) => {
    DaafPopup.open(<TrdQueryFailInfoPopup info={errorMessage} />, {
      style: { content: { width: "750px" } },
    });
  };

  /**
   * 쿼리 단독 실행 -> 실행 오류 쿼리 목록에서 사용
   * @param {*} queryData
   * @param {*} index
   * @returns
   */
  const executeSingleQuery = async (queryData, index) => {
    const connection = SessionUtils.get("connection");
    if (!connection) return DaafMessage.alert("연결 정보가 없습니다.", "error");
    queryData.errorMessage = "";
    queryData.queryExecuteYn = "Y";
    const queryResult = await TrdService.excuteQuery({
      ...connection,
      queryList: [queryData],
    });
    if (!queryResult.isError) {
      const querySingleResult = queryResult.data.data[0];
      const updatedData = {
        ...queryData,
        ...querySingleResult,
        errorCheckedYn: "Y",
      };
      const newErrorQueryGridData = [...errorQueryGridData];
      newErrorQueryGridData[index] = updatedData;
      setErrorQueryGridData(newErrorQueryGridData);
      //싱글쿼리 실행이 성공이면 해당 내용 업데이트
      if (updatedData.querySuccessYn === "Y") {
        updatedData.errorCheckedUserId = UserUtils.getId();
        const updateResult = await TrdService.updateTrdQuery(updatedData);
      } else {
        DaafMessage.alert("쿼리 실행 중 오류 발생", "warning");
        showErrorLogPopup(querySingleResult.queryErrorMessage);
      }
    }
  };

  /**
   * 실행 실패한 쿼리를 갱신하는 기능
   * baseTrdMstHistoryId 에 맞춰서 history를 비교하고 쿼리를 재생성한다.
   * @param {*} e
   * @param {*} data
   */
  const refreshQuery = async (e, data, index) => {
    const messageResult = await confirmMessage(
      "쿼리를 현재 테이블에 맞춰 갱신합니다."
    );
    if (!messageResult) return false;
    const { baseTrdMstHistoryId, tableNm, fieldNm, queryKey, tableNm2 } = data;
    // 첫 쿼리 실행시 오류가 발생하는 경우는 baseTrdMstHistoryId = 0 으로 저장된다.
    // 이때는 쿼리 갱신이 아닌, create로 판단한다.
    const isCreated = baseTrdMstHistoryId === 0;
    let prevTrd = null;
    if (!isCreated) {
      prevTrd = await TrdService.getTrdMstHistoryDetailInfo({
        trdHistoryId: baseTrdMstHistoryId,
      });
    }
    let dbType = env.dbType;
    const connection = SessionUtils.get("connection");
    if (connection) {
      dbType = connection.databaseType;
    }
    let table;
    let column;
    let relation;
    let refreshedData;
    let changedData;
    if (prevTrd) {
      changedData = TrdUtil.getChangedData(trd, prevTrd.data);
    }

    switch (queryKey) {
      case Enums.QueryKey.TABLE.CREATE:
        if (!ArrayUtils.isArray(trd.tableList)) break;
        table = trd.tableList.find((t) => t.tablePhysicalNm === tableNm);
        refreshedData = TrdUtil.generateTableCreateQuery(table, dbType);
        break;
      case Enums.QueryKey.TABLE.DROP:
        if (!ArrayUtils.isArray(trd.tableList)) break;
        table = prevTrd.data.tableList.find(
          (t) => t.tablePhysicalNm === tableNm
        );
        refreshedData = TrdUtil.generateTableDropQuery(table, trd, dbType);
        break;
      case Enums.QueryKey.COLUMN.ADD:
        table = trd.tableList.find((t) => t.tablePhysicalNm === tableNm);
        column = table.trdTableField.find(
          (f) => f.element?.elementCd === fieldNm
        );
        if (column) {
          refreshedData = TrdUtil.generateAddColumnQuery(
            column,
            table.tablePhysicalNm,
            dbType
          );
        }
        break;
      case Enums.QueryKey.COLUMN.ALTER:
        if (!ObjectUtils.isEmpty(changedData)) {
          const changedTable = changedData.changedTableList.find(
            (t) => t.tableNm === tableNm
          );
          if (!changedTable) {
            deleteQuery(
              e,
              data,
              index,
              `
              기준 데이터와 차이점이 없습니다.
              해당 수정 내용을 삭제 합니다.
            `
            );
            return false;
          }
          const willUpdateField = changedTable.trdTableField.updatedField.find(
            (f) => f.fieldNm === fieldNm
          );

          if (willUpdateField) {
            refreshedData = TrdUtil.generateFieldUpdateQuery({
              field: willUpdateField,
              dbType,
              tableNm,
            });
          } else {
            deleteQuery(
              e,
              data,
              index,
              `
              기준 데이터와 차이점이 없습니다.
              해당 수정 내용을 삭제 합니다.
            `
            );
          }
        }
        break;
      case Enums.QueryKey.COLUMN.DROP:
        if (!prevTrd)
          return DaafMessage.alert(
            "기준 데이터가 확인되지 않습니다.",
            "warning"
          );
        table = prevTrd.data.tableList.find(
          (t) => t.tablePhysicalNm === tableNm
        );
        column = table.trdTableField.find(
          (f) => f.element?.elementCd === fieldNm
        );
        if (column) {
          refreshedData = TrdUtil.generateDropColumnQuery(
            column,
            table.tablePhysicalNm,
            dbType
          );
        }

        break;
      case Enums.QueryKey.COLUMN.PK:
        let isInitPk = false;
        //이전에 쿼리가 돌아갔던 데이터 기준으로 당시 PK가 Y인 칼럼이 있었는지 확인 후 PK 규약 초기화 여부를 결정한다.
        if (!ObjectUtils.isEmpty(changedData)) {
          const changedTable = changedData.changedTableList.find(
            (t) => t.tableNm === tableNm
          );
          isInitPk = changedTable.trdTableField.updatedField.some(
            (f) => f.changed.pkYn?.prevData === "Y"
          );
        }
        table = trd.tableList.find((t) => t.tablePhysicalNm === tableNm);
        refreshedData = TrdUtil.generateSetPKColumn({
          dbType: dbType,
          fieldList: table.trdTableField,
          tableNm: table.tablePhysicalNm,
          pkInit: isInitPk,
        });
        break;
      case Enums.QueryKey.COLUMN.DROP_PK:
        refreshedData = TrdUtil.generatePkDropQuery(tableNm);
        break;
      case Enums.QueryKey.COLUMN.UNIQUE:
        let isInitUnique = false;
        //이전에 쿼리가 돌아갔던 데이터 기준으로 당시 unique가 Y인 칼럼이 있었는지 확인 후 unique Constraint 초기화 여부를 결정한다.
        if (!ObjectUtils.isEmpty(changedData)) {
          const changedTable = changedData.changedTableList.find(
            (t) => t.tableNm === tableNm
          );
          isInitUnique = changedTable.trdTableField.updatedField.some(
            (f) => f.changed.uniqueYn?.prevData === "Y"
          );
        }
        table = trd.tableList.find((t) => t.tablePhysicalNm === tableNm);
        refreshedData = TrdUtil.generateSetUniqueColumn({
          dbType,
          fieldList: table.trdTableField,
          tableNm: table.tablePhysicalNm,
          uniqueInit: isInitUnique,
        });
        break;
      case Enums.QueryKey.RELATION.ALTER:
        table = trd.tableList.find((t) => t.tablePhysicalNm === tableNm);
        refreshedData = TrdUtil.generateTableRelationChangeQuery(
          table,
          trd,
          dbType
        );
        break;
      case Enums.QueryKey.RELATION.DROP:
        table = trd.tableList.find((t) => t.tablePhysicalNm === tableNm);
        return DaafMessage.alert("개발 중입니다.", "info");
        // let relation = table.relation.find(rel=>rel)
        // refreshedData = TrdUtil.generateForeignKeyDropQuery(
        //   tableNm,
        //   tableNm2,
        //   trd,
        //   dbType
        // );
        break;

      default:
        DaafMessage.alert(
          "쿼리 키가 정의되지 않았습니다. 관리자에게 문의해주세요.",
          "error"
        );
        break;
    }
    /**
     * 갱신된 쿼리는 쿼리배열의 맨 위로 이동한다.
     * Create Table이 있는 경우 쿼리 배열에서 tableNm이 동일한 쿼리들은 삭제한다.
     */
    if (ObjectUtils.isEmpty(refreshedData)) {
      const queryCheck = await confirmMessage(
        "쿼리 갱신 결과, 유효한 변경점을 찾을 수 없습니다. 삭제하시겠습니까?"
      );
      if (queryCheck) {
        const deleteResult = await TrdService.deleteQuery(data);
        if (!deleteResult.isError) {
          const qData = [...errorQueryGridData];
          qData.splice(index, 1);
          setErrorQueryGridData(qData);
        }
      }

      return false;
    }
    const QuerySet = {
      ...data,
      ...refreshedData,
      baseTrdMstHistoryId,
      queryExecuteYn: "Y",
      errorCheckedUserId: UserUtils.getId(),
      queryCompId: StringUtils.getUuid(),
    };
    //오류난 쿼리 목록에서 제외
    const newErrorQueryData = [...errorQueryGridData];
    newErrorQueryData.splice(index, 1);
    setErrorQueryGridData(newErrorQueryData);

    //쿼리 상태창에 추가
    setQueryGridData([QuerySet, ...queryGridData]);
    // setQueryGridData(queryGridData);
  };

  /**
   * 쿼리 삭제
   */
  const deleteQuery = async (e, data, index, text) => {
    const queryQuestion = await confirmMessage(
      text || "해당 쿼리 내용 및 이력을 삭제 하시겠습니까?"
    );
    if (!queryQuestion) return false;
    const deleteResult = await TrdService.deleteQuery(data);
    if (!deleteResult.isError) {
      const qData = [...errorQueryGridData];
      qData.splice(index, 1);
      setErrorQueryGridData(qData);
    }
  };

  return (
    <DaafModal>
      <DaafModal.Header title={`${trd.info.trdNm} 쿼리 미리보기`} />
      <DaafModal.Body>
        <UModalTemplate>
          {!ArrayUtils.isEmpty(errorQueryGridData) && (
            <>
              <Form.Label>이전 실행 오류 쿼리 목록</Form.Label>
              <Grid
                className="mb-3"
                rows={errorQueryGridData}
                columns={[
                  {
                    field: "queryComment",
                    headerName: "변경사항",
                  },
                  {
                    field: "queryDetail",
                    headerName: "쿼리 상세",
                    renderCell: (data, index) => {
                      return (
                        <Button
                          variant="outline-primary"
                          size="sm"
                          onClick={(e) =>
                            onViewQuery(data.queryContents, index)
                          }
                        >
                          상세보기
                        </Button>
                      );
                    },
                  },
                  {
                    field: "queryErrorMessage",
                    headerName: "쿼리 내용",
                    renderCell: (data, index) => {
                      if (data.querySuccessYn === "Y") {
                        <Button variant="primary" size="sm">
                          성공
                        </Button>;
                      } else {
                        return (
                          <Button
                            variant="outline-danger"
                            size="sm"
                            onClick={(e) =>
                              DaafPopup.open(
                                <TrdQueryFailInfoPopup
                                  info={data.queryErrorMessage}
                                />,
                                { style: { content: { width: "550px" } } }
                              )
                            }
                          >
                            오류 내용
                          </Button>
                        );
                      }
                    },
                  },
                  {
                    field: "errorCheckedYn",
                    headerName: "확인 여부",
                    renderCell: (data, index) => {
                      if (
                        StringUtils.equalsIgnoreCase(data.errorCheckedYn, "Y")
                      ) {
                        return (
                          <Button
                            variant="success"
                            onClick={(e) => checkErrorQuery(data, index, "N")}
                            size="sm"
                          >
                            확인
                          </Button>
                        );
                      } else {
                        return (
                          <Button
                            variant="outline-success"
                            onClick={(e) => checkErrorQuery(data, index, "Y")}
                            size="sm"
                          >
                            미확인
                          </Button>
                        );
                      }
                    },
                  },
                  {
                    field: "rework",
                    headerName: "쿼리 갱신",
                    renderCell: (data, index) => {
                      return (
                        <Button
                          size="sm"
                          onClick={(e) => refreshQuery(e, data, index)}
                        >
                          쿼리 갱신
                        </Button>
                      );
                    },
                  },
                  {
                    field: "rework",
                    headerName: "삭제",
                    renderCell: (data, index) => {
                      return (
                        <Button
                          size="sm"
                          onClick={(e) => deleteQuery(e, data, index)}
                          variant="outline-danger"
                        >
                          <MdDelete />
                        </Button>
                      );
                    },
                  },
                ]}
              />
            </>
          )}

          <Form.Label>변경사항 목록</Form.Label>
          <TrdQueryStateGrid
            editable={true}
            queryList={queryGridData}
            setQueryList={setQueryGridData}
          />
        </UModalTemplate>
      </DaafModal.Body>
      <DaafModal.Footer>
        {queryExecuted ? (
          <DaafModal.Footer.Button onClick={(e) => DaafPopup.close()}>
            확인
          </DaafModal.Footer.Button>
        ) : (
          <DaafModal.Footer.ProgressButton
            side="right"
            onClick={onClickConfirm}
          >
            저장 및 전송
          </DaafModal.Footer.ProgressButton>
        )}
      </DaafModal.Footer>
    </DaafModal>
  );
};

export default TrdQueryPopup;
