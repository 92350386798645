const {
  BaseService,
  ArrayUtils,
} = require("@alpha/com.bizentro.daaf.front.framework");

class TrdService extends BaseService {
  static api = new BaseService("/trd");

  /**
   * Trd 저장
   * @param {*} data
   * @returns
   */
  static save = (data) => {
    return this.api.sendPost("/saveTrd", data);
  };

  /**
   * Trd 목록 호출
   * @param {*} data
   * @returns
   */
  static getTrdList = (data) => {
    return this.api.sendPost("/getTrdList", data);
  };

  /**
   * 단일 TRD 호출
   * @param {*} data
   * @returns
   */
  static getTrd = (data) => {
    return this.api.sendPost("/getTrd", data);
  };

  /**
   * TRD 삭제
   * @param {*} data
   * @returns
   */
  static deleteTrd = (data) => {
    return this.api.sendPost("/deleteTrd", data);
  };

  /**
   * TRD 이력 MST 목록 호출
   * @param {*} data
   * @returns
   */
  static getTrdHistoryMstList = (data) => {
    return this.api.sendPost("/getTrdHistoryMstList", data);
  };

  /**
   * TRD 이력 목록 호출
   * @param {*} data
   * @returns
   */
  static getTrdMstHistoryList = (data) => {
    return this.api.sendPost("/getTrdMstHistoryList", data);
  };

  /**
   * TRD MST 히스토리 상세 정보 호출
   * @param {*} data
   * @returns
   */
  static getTrdMstHistoryDetailInfo = (data) => {
    return this.api.sendPost("/getTrdMstHistoryDetailInfo", data);
  };

  static getPrevAppliedTrdMst = (data) => {
    return this.api.sendPost("/getPrevAppliedTrdMst", data);
  };

  static getFailedQueryHistory = (data) => {
    return this.api.sendPost("/getFailedQueryHistory", data);
  };

  /**
   * Trd 쿼리 실행
   * @param {*} data
   * @returns
   */
  static excuteQuery = (data) => {
    return this.api.sendPost("/excuteQuery", data);
  };

  /**
   * Error 쿼리 확인 로직
   * @param {*} data
   * @returns
   */
  static updateTrdQuery = (data) => {
    return this.api.sendPost("/updateTrdQuery", data);
  };

  /**
   * 쿼리 삭제
   * @param {*} data
   * @returns
   */
  static deleteQuery = (data) => {
    return this.api.sendPost("/deleteQuery", data);
  };

  /**
   * Trd에 Table이 있는지 확인->이름으로 확인함
   * @param {*} data
   * @param {String} data.tablePhysicalNm
   * @param {Number} data.appEnvId
   * @returns
   */
  static getTrdTable = (data) => {
    return this.api.sendPost("/getTrdTable", data);
  };

  /**
   * Table 정보 호출
   * ID로 확인
   * @param {*} data
   * @param {String} data.tablePhysicalNm
   * @param {Number} data.appEnvId
   * @returns
   */
  static getTableInfo = (data) => {
    return this.api.sendPost("/getTableInfo", data);
  };

  /**
   * Import 되고 있는 TRD 정보 확인
   * ID로 확인
   * @param {*} data
   * @param {String} data.tablePhysicalNm
   * @param {Number} data.appEnvId
   * @returns
   */
  static getTableImportInfo = (data) => {
    return this.api.sendPost("/getTableImportInfo", data);
  };

  /**
   * 테이블 목록에서 import table의 필드를 체워주는 함수
   * @param {*} tableList
   * @returns {Array} tableList
   */
  static setImportTableField = async (tableList) => {
    const tableListCP = JSON.parse(JSON.stringify(tableList));
    const importTableList = tableList.filter((t) => t.importTableId);
    return new Promise(async (res, rej) => {
      if (ArrayUtils.isEmpty(importTableList)) {
        return res(tableList);
      } else {
        const importTableWithField = await Promise.all(
          tableListCP.map((it) => {
            return new Promise(async (res, rej) => {
              if (!it.importTableId) {
                res(it);
              } else {
                const result = await TrdService.getTableInfo({
                  tableMstId: it.importTableId,
                });
                if (!result.isError) {
                  it.trdTableField = result.data.trdTableField;
                }
                res(it);
              }
            });
          })
        );
        res(importTableWithField);
      }
    });
  };

  /**
   *
   * @param {object} element
   * @returns tableFieldList
   */
  static getTableListByElement = (element) => {
    return this.api.sendPost("/getTableListByElement", element);
  };

  /**
   * 검색하고자 하는 테이블 명이 있는 Trd 목록을 리턴함
   * @param {object} table
   * @returns tableFieldList
   */
  static getTrdListByTable = (table) => {
    return this.api.sendPost("/getTrdListByTable", table);
  };

  /**
   * Trd 상관 없이 모든 테이블 목록을 호출함
   * @param {*} body
   * @returns
   */
  static getTableListWithoutTrd = (body) => {
    return this.api.sendPost("/getTableListWithoutTrd", body);
  };
}

export default TrdService;
