import { StringUtils } from "@alpha/com.bizentro.daaf.front.framework";
import Form from "components/common/form/Form";
import { useCallback, useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { Placeholder, Table } from "react-bootstrap";
import { RiArrowUpDownFill } from "react-icons/ri";

const errorMessage = {
  getId: `Please Set "getId" Parameter for Grid Component`,
};

/**
 * Grid 컴포넌트
 *
 * @param {Object} props - 컴포넌트의 속성
 * @param {Array} props.columns - 그리드의 컬럼 정의
 * @param {Array} props.rows - 그리드의 행 데이터
 * @param {Function} props.onRowClick - 행 클릭 시 호출되는 함수
 * @param {Function} props.onRowDoubleClick - 행 더블 클릭 시 호출되는 함수
 * @param {Function} props.onDragDropChange - 드래그 앤 드롭 변경 시 호출되는 함수
 * @param {boolean} props.numbering - 행 번호를 표시할지 여부
 * @param {string} props.className - 추가적인 CSS 클래스
 * @param {Function} props.getId - 각 행에서 ID를 가져오는 로직
 * @param {Object} props.selected - 선택된 아이템
 * @param {boolean} props.stickyHeader - 헤더를 상단에 고정할지 여부
 * @param {boolean} props.rowDraggable - 행을 드래그 가능하게 할지 여부
 * @param {Object} props.bodyStyle - 바디 스타일
 * @param {React.CSSProperties} props.gridBoxStyle - 그리드 박스 스타일
 * @param {Boolean} props.dataCountVisible - 데이터 갯수 표시 여부
 * @param {Boolean} props.isLoading - 데이터 호출 중 여부
 * @param {String} props.emptyMessage - 표시할 데이터가 없을 때 보여줄 메세지
 * @param {Boolean} props.useCheckBox - Check box  사용여부
 * @param {Function} props.onChangeCheckbox - Check box change 이벤트
 * @returns {ReactElement} Grid 컴포넌트
 */
const Grid = ({
  columns = [],
  rows = [],
  onRowClick: _onRowClick = () => {},
  onRowDoubleClick = () => {},
  onDragDropChange = () => {},
  numbering,
  className,
  getId, //각 ROw에서 Id 가져오는 로직
  selected, //선택된 Item
  stickyHeader = false, //헤더 상단 고정
  rowDraggable = false, //Row 드래그 가능 여부
  bodyStyle = {},
  gridBoxStyle = {},
  dataCountVisible = false,
  isLoading = false,
  emptyMessage,
  useCheckBox = false,
  onChangeCheckbox: _onChangeCheckbox,
  ...props
}) => {
  const sendErrorMessage = useCallback((errorKey) => {
    console.error(errorMessage[errorKey]);
  }, []);

  const [selectedRowList, setSelectedRowList] = useState([]);
  // useEffect(() => {
  //   selectedRowListRef.current = [];
  //   setSelectedRowList([]);
  // }, [rows]);

  /**
   * DND Ref 값
   */
  const rowDndRef = useRef({
    draggedRowData: null,
    dragIndex: null,
    dropRowData: null,
    dropIndex: null,
    dropDirection: null,
  });

  /**
   * Drag Start 할때
   * @param {*} e
   * @param {*} row
   * @param {*} index
   */
  const onTrDragStart = (e, row, index) => {
    rowDndRef.current.draggedRowData = row;
    rowDndRef.current.dragIndex = index;
  };

  /**
   * Drag가 대상위로 지나갈 때
   * @param {*} dragTargetRow
   * @param {*} direction
   * @param {*} index
   */
  const onTrDragOver = (dragTargetRow, direction, index) => {
    rowDndRef.current.dropRowData = dragTargetRow;
    rowDndRef.current.dropDirection = direction;
    rowDndRef.current.dropIndex = index;
  };

  /**
   * 드래그 끝날떄
   * @param {*} e
   * @param {*} row
   * @returns
   */
  const onTrDrop = (e, row) => {
    const newRow = [...rows];
    const { dragIndex, draggedRowData, dropDirection, dropIndex } =
      rowDndRef.current;
    if (dropIndex === dragIndex) return false;

    //옮기고자 하는 row는 배열에서 삭제
    newRow.splice(dragIndex, 1);

    if (dropDirection === "up") {
      //놓는 방향이 대상의 위 방향이면
      if (dragIndex < dropIndex) {
        // 옮기는 대상이 drop위치보다 위에 있는 경우
        // 데이터는 row에서 이미 삭제 되었기 때문에 dropIndex 보다 1 작게 준다.
        newRow.splice(dropIndex - 1, 0, draggedRowData);
      } else {
        newRow.splice(dropIndex, 0, draggedRowData);
      }
    } else if (dropDirection === "down") {
      //놓는 방향이 대상의 아래 방향이면
      if (dragIndex > dropIndex) {
        // 옮기는 대상이 drop위치보다 뒤에 있는 경우
        // 데이터는 row에서 이미 삭제 되었기 때문에 dropIndex 보다 1 크게 준다.
        newRow.splice(dropIndex + 1, 0, draggedRowData);
      } else {
        newRow.splice(dropIndex, 0, draggedRowData);
      }
    }
    if (onDragDropChange) {
      onDragDropChange(newRow);
    }
  };

  /**
   * Row 선택 기능 선택시 전체 선택 기능
   * @param {React.ChangeEvent<HTMLInputElement>} e
   */
  const onChangeCheckboxAll = (e) => {
    e.stopPropagation();
    // e.preventDefault();
    if (!getId) {
      return sendErrorMessage("getId");
    }
    let selectedList = [];
    if (e.target.checked) {
      selectedList = rows;
      setSelectedRowList(rows);
    } else {
      selectedList = [];
      setSelectedRowList([]);
    }
    if (_onChangeCheckbox) {
      _onChangeCheckbox(selectedList);
    }
  };
  /**
   * checkbox 선택하는 기능
   * @param {React.ChangeEvent<HTMLInputElement>} e
   * @param {*} data
   * @returns
   */
  const onChangeCheckbox = (e, data) => {
    e.stopPropagation();
    // e.preventDefault();
    if (!getId) {
      return sendErrorMessage("getId");
    }
    let list = [...selectedRowList];
    if (e.target.checked) {
      list.push(data);
    } else {
      const index = list.findIndex((row) => getId(row) === getId(data));
      if (index > -1) {
        list.splice(index, 1);
      }
    }
    setSelectedRowList(list);
    if (_onChangeCheckbox) {
      _onChangeCheckbox(list);
    }
  };

  const renderHeader = () => {
    return (
      <thead style={stickyHeader ? { position: "sticky" } : {}}>
        <tr>
          {rowDraggable && <th />}
          {useCheckBox && (
            <th className="numbering">
              <input
                type="checkbox"
                onChange={onChangeCheckboxAll}
                checked={rows.length === selectedRowList.length}
              />
            </th>
          )}
          {numbering && <th className="numbering" />}
          {columns.map((col, colInde) => {
            return (
              <th
                key={`header_${col.field}_${colInde}`}
                style={{
                  ...col.headerStyle,
                }}
              >
                {col.headerName}
              </th>
            );
          })}
        </tr>
      </thead>
    );
  };

  const renderSkeleton = () => {
    return (
      <Table
        hover
        style={{ marginBottom: "0", ...props.style }}
        className={className}
      >
        {renderHeader()}
        <tbody>
          {Array(8)
            .fill()
            .map((item, index) => {
              return (
                <tr key={index}>
                  {numbering && <th className="numbering"></th>}
                  {columns.map((col, colIndex) => {
                    return (
                      <td key={colIndex}>
                        <Placeholder xs={12} bg={"secondary"} />
                      </td>
                    );
                  })}
                </tr>
              );
            })}
        </tbody>
      </Table>
    );
  };

  /**
   * Grid Body Render
   * @param {*} row
   * @param {*} index
   * @returns
   */
  const renderBodyRow = (row, index) => {
    let checked = false;
    if (getId) {
      checked = selectedRowList.find((data) => getId(data) === getId(row))
        ? true
        : false;
    }

    return (
      <>
        {useCheckBox && (
          <th className="numbering">
            <input
              type="checkbox"
              onChange={(e) => onChangeCheckbox(e, row)}
              checked={checked}
            />
          </th>
        )}
        {numbering && <th className="numbering">{index + 1}</th>}
        {columns.map((col, colIndex) => {
          if (col.renderCell) {
            return (
              <td
                style={{ textAlign: col.center ? "center" : "", ...col.style }}
                key={colIndex}
              >
                {col.renderCell(row, index)}
              </td>
            );
          } else {
            return (
              <td style={{ ...col.style }} key={colIndex}>
                {row[col.field]}
              </td>
            );
          }
        })}
      </>
    );
  };

  /**
   * RowClick 재구성
   */
  const onRowClick = (e, data, index) => {
    if (useCheckBox) {
      //체크박스를 사용할때는 지정된 Row 클릭보다 checkbox 선택으로 함
      const isSelected = selectedRowList.find((r) => getId(r) === getId(data))
        ? true
        : false;
      e.target.checked = !isSelected;
      onChangeCheckbox(e, data);
    } else {
      if (_onRowClick) {
        _onRowClick(e, data, index);
      }
    }
  };

  if (isLoading) {
    return renderSkeleton();
  } else {
    return (
      <>
        {dataCountVisible && rows.length > 0 && `(${rows.length}개 데이터)`}
        <div
          style={{ ...gridBoxStyle, overflow: "auto", position: "relative" }}
        >
          {emptyMessage && rows.length === 0 && (
            <div
              style={{
                position: "absolute",
                height: `calc(100% - 35px) `,
                width: "100%",
                top: "35px",
                background: "#8282824e",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // color: "whitesmoke",
              }}
            >
              {emptyMessage}
            </div>
          )}

          <Table
            hover
            style={{ marginBottom: "0", ...props.style }}
            className={className}
          >
            {renderHeader()}
            <tbody>
              {rows.map((row, index) => {
                if (rowDraggable) {
                  return (
                    <DraggableRow
                      onTrDragStart={onTrDragStart}
                      onTrDrop={onTrDrop}
                      onTrDragOver={onTrDragOver}
                      columns={columns}
                      row={row}
                      getId={getId}
                      index={index}
                      numbering={numbering}
                      onRowClick={onRowClick}
                      onRowDoubleClick={onRowDoubleClick}
                      useCheckBox={useCheckBox}
                      selected={selected}
                      key={index}
                    >
                      {renderBodyRow(row, index)}
                    </DraggableRow>
                  );
                } else
                  return (
                    <tr
                      key={index}
                      onClick={(e) => onRowClick(e, row, index)}
                      onDoubleClick={(e) => onRowDoubleClick(e, row, index)}
                      className={
                        selected && getId
                          ? StringUtils.equalsIgnoreType(
                              getId(selected),
                              getId(row)
                            )
                            ? "selected"
                            : ""
                          : ""
                      }
                    >
                      {renderBodyRow(row, index)}
                    </tr>
                  );
              })}
            </tbody>
          </Table>
        </div>
      </>
    );
  }
};

export default Grid;

const DraggableRow = ({
  columns,
  row,
  numbering,
  index,
  selected,
  getId,
  onRowClick,
  onRowDoubleClick,
  onTrDragStart,
  onTrDrop,
  onTrDragOver,
  ...props
}) => {
  const ref = useRef();
  const mouseYRef = useRef();

  const onDragOver = (e, row, index) => {
    e.preventDefault();
    const mouseY = e.clientY;
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();

      if (Math.abs(mouseY - mouseYRef.current) < rect.height * 0.1) {
        return false;
      } else mouseYRef.current = mouseY;

      const centerY = rect.top + rect.height / 2;

      if (mouseY < centerY) {
        ref.current.style.borderTop = "10px solid var(--main-color)";
        ref.current.style.removeProperty("border-bottom");
        onTrDragOver(row, "up", index);
      } else {
        ref.current.style.borderBottom = "10px solid var(--main-color)";
        ref.current.style.removeProperty("border-top");
        onTrDragOver(row, "down", index);
      }
    }
  };

  const onDragLeave = (e, row) => {
    ref.current.style.removeProperty("border-bottom");
    ref.current.style.removeProperty("border-top");
  };

  return (
    <tr
      ref={ref}
      draggable
      onClick={(e) => onRowClick(e, row, index)}
      onDoubleClick={(e) => onRowDoubleClick(e, row, index)}
      className={
        selected && getId
          ? StringUtils.equalsIgnoreType(getId(selected), getId(row))
            ? "selected"
            : ""
          : ""
      }
      onDragStart={(e) => onTrDragStart(e, row, index)}
      onDragOver={(e) => onDragOver(e, row, index)}
      onDragEnd={(e) => onTrDrop(e, row)}
      onDragLeave={onDragLeave}
      onDrop={onDragLeave}
      onDragCapture={(e) => false}
    >
      <th className="row-draggable">
        <RiArrowUpDownFill />
      </th>
      {props.children}
    </tr>
  );
};
