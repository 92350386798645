import {
  CommonUtils,
  DaafModal,
  DaafModalTemplate,
  UserUtils,
} from "@alpha/com.bizentro.daaf.front.framework";
import Form from "components/common/form/Form";
import { useState } from "react";
import { Button, InputGroup } from "react-bootstrap";

/**
 * TrdQueryViewerPopup 컴포넌트
 *
 * @param {Object} props - 컴포넌트의 속성
 * @param {Object} props.query - 쿼리 데이터
 * @param {Function} props.callback - 콜백 함수
 * @param {boolean} props.editable - 쿼리 데이터가 수정 가능한지 여부
 * @returns {ReactElement} TrdQueryViewerPopup 컴포넌트
 */
const TrdQueryViewerPopup = ({
  query: _query,
  callback,
  editable,
  ...props
}) => {
  const [comment, setComment] = useState("");
  const [query, setQuery] = useState(_query);

  const onAddComment = () => {
    let newComment = `\n/* ${comment} */ \n`;
    const author = `/* - ${UserUtils.getNm()} ${CommonUtils.getDate(
      new Date(),
      "datetime"
    )} */ \n`;
    setQuery(query + newComment + author);
  };

  const onClickConfirm = () => {
    if (callback) {
      callback(query);
    }
  };

  return (
    <DaafModal>
      <DaafModal.Header title="쿼리 상세" />
      <DaafModal.Body>
        <DaafModalTemplate>
          <Form.Textarea
            value={query}
            rows={20}
            style={{ height: "550px", whiteSpace: "pre-wrap" }}
            className="mb-3"
            readOnly
          />
          {editable && (
            <>
              <Form.Label>주석 입력</Form.Label>
              <InputGroup>
                <Form.DInput
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  placeholder={"추가할 주석을 입력"}
                />
                <Button size="sm" onClick={onAddComment}>
                  주석 추가
                </Button>
              </InputGroup>
            </>
          )}
        </DaafModalTemplate>
      </DaafModal.Body>
      <DaafModal.Footer>
        <DaafModal.Footer.Button onClick={onClickConfirm}>
          Confirm
        </DaafModal.Footer.Button>
      </DaafModal.Footer>
    </DaafModal>
  );
};

export default TrdQueryViewerPopup;
