import {
  ArrayUtils,
  CommonUtils,
  DaafEnums,
  DaafMessage,
  DaafPopup,
  StringUtils,
} from "@alpha/com.bizentro.daaf.front.framework";
import { AppPath } from "app/Enums";
import Form from "components/common/form/Form";
import Template from "components/common/Template";
import Grid from "components/grid/Grid";
import TrdTableInitCreatePopup from "components/popup/trd/TrdTableInitCreatePopup";
import { useRef } from "react";
import { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { MdDelete, MdSearch } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import TrdService from "services/TrdService";
import { patchTrd } from "store/actions/TrdAction";
import { stopEvent } from "utils/CommonUtils";
import useAppEnv from "utils/hook/useAppEnv";

const TrdList = ({ ...props }) => {
  const [trdList, setTrdList] = useState([]);
  const trdListRef = useRef([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [tableSearchTerm, setTableSearchTerm] = useState("");
  const { app, env } = useAppEnv();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const columns = [
    {
      field: "trdUid",
      headerName: "Trd UID",
      style: {
        width: "350px",
      },
    },
    {
      field: "trdNm",
      headerName: "Trd 명",
      style: {
        width: "350px",
      },
    },
    {
      field: "trdDesc",
      headerName: "TRD 설명",
      style: {
        maxWidth: "500px",
      },
    },
    {
      field: "trdDevType",
      headerName: "개발 유형",
    },
    {
      field: "trdAreaType",
      headerName: "영역 유형",
    },

    {
      field: "updtUserId",
      headerName: "수정자",
    },
    {
      field: "movoToTableList",
      headerName: "목록",
      renderCell: (data) => {
        return (
          <Button
            onClick={(e) => {
              stopEvent(e);
              getTrdTableList(e, data);
            }}
          >
            테이블 목록
          </Button>
        );
      },
    },
    {
      field: "updtDt",
      headerName: "수정일",
      renderCell: (data) => {
        return CommonUtils.getDate(data.updtDt);
      },
    },
    {
      field: "delete",
      headerName: "삭제",
      renderCell: (data) => {
        return (
          <Button
            variant="outline-danger"
            onClick={(e) => {
              stopEvent(e);
              DaafMessage.confirm(`${data.trdNm}을 삭제하시겠습니까?`, () => {
                onDeleteTrd(data);
              });
            }}
            size="sm"
          >
            <MdDelete />
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    getTrdList();
  }, []);

  const getTrdList = async () => {
    const result = await TrdService.getTrdList({ appEnvId: env.appEnvId });
    if (ArrayUtils.isArray(result.data)) {
      setTrdList(result.data || []);
      trdListRef.current = result.data;
    }
  };

  const getTrdData = async (data) => {
    const result = await TrdService.getTrd({ ...data });
    if (result.isError) {
      DaafMessage.alert("오류가 발생했습니다.", DaafEnums.MessageType.ERROR);
      return false;
    } else {
      return result.data;
    }
  };

  const getTrd = async (event, data) => {
    const resultData = await getTrdData(data);
    if (resultData) {
      dispatch(patchTrd(resultData));
      if (resultData.tableList.length > 50) {
        navigate(AppPath.TRD.Editor_List.url);
      } else {
        navigate(AppPath.TRD.Editor.url);
      }
    }
  };
  const getTrdTableList = async (e, data) => {
    const resultData = await getTrdData(data);
    dispatch(patchTrd(resultData));
    navigate(AppPath.TRD.Editor_List.url);
  };

  const onDeleteTrd = async (data) => {
    // return console.log(data);
    const result = await TrdService.deleteTrd(data);
    if (result.isError)
      DaafMessage.alert("오류가 발생했습니다.", DaafEnums.MessageType.ERROR);
    else DaafMessage.alert("삭제 되었습니다.", DaafEnums.MessageType.SUCCESS);
    getTrdList();
  };

  const onChangeSearchTerm = (e) => {
    const trdUid = e.target.value.toLowerCase();
    if (trdUid) {
      const filtered = trdListRef.current.filter(
        (trd) =>
          trd.trdUid.toLowerCase().indexOf(trdUid) > -1 ||
          trd.trdNm.toLowerCase().indexOf(trdUid) > -1
      );
      setTrdList(filtered);
    } else {
      setTrdList(trdListRef.current);
    }
    setSearchTerm(e.target.value);
  };

  const onSeachTrdByTable = async (e) => {
    e.preventDefault();
    const result = await TrdService.getTrdListByTable({
      tablePhysicalNm: tableSearchTerm,
    });
    setTrdList(result.data);
  };

  const onChangeTableSearchTerm = (e) => {
    setTableSearchTerm(e.target.value);
    if (StringUtils.isEmpty(e.target.value)) {
      setTrdList(trdListRef.current);
    }
  };

  /**
   * 생성된 TRD 를 기준으로 테이블을 생성하는 버튼
   * @param {*} e
   */
  const onClickDBInitialize = (e) => {
    DaafPopup.open(<TrdTableInitCreatePopup />, {
      style: { content: { width: "1200px" } },
    });
  };

  return (
    <Template
      title={"TRD 목록"}
      breadcrumb={
        <>
          <div>TRD</div>/<div>목록</div>
        </>
      }
    >
      <Row className="mb-3">
        <Col xs={1}>
          <Button onClick={onClickDBInitialize}>TRD 테이블 생성</Button>
        </Col>
        <Col xs={5}></Col>
        <Col
          xs={1}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            fontWeight: "bold",
          }}
        >
          테이블 검색
        </Col>
        <Col xs={2}>
          <Form.InputGroup>
            <Form.DInput
              value={tableSearchTerm}
              onChange={onChangeTableSearchTerm}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  onSeachTrdByTable(e);
                }
              }}
              size={"sm"}
              placeholder={"테이블 명 입력 후 Enter"}
            />
            <Button size={"sm"} onClick={onSeachTrdByTable}>
              <MdSearch />
            </Button>
          </Form.InputGroup>
        </Col>
        <Col
          xs={1}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            fontWeight: "bold",
          }}
        >
          TRD 검색
        </Col>
        <Col xs={2}>
          <Form.InputGroup>
            <Form.DInput
              value={searchTerm}
              onChange={onChangeSearchTerm}
              size={"sm"}
              placeholder={"Trd UID 또는 이름 입력"}
            />
            <Button size={"sm"}>
              <MdSearch />
            </Button>
          </Form.InputGroup>
        </Col>
      </Row>
      <Grid
        numbering
        columns={columns}
        rows={trdList}
        onRowClick={getTrd}
        stickyHeader={true}
        gridBoxStyle={{ maxHeight: "650px" }}
      />
    </Template>
  );
};

export default TrdList;
